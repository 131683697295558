import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'sw',
    component: () => import(/* webpackChunkName: "about" */ '../views/sw.vue'),
    meta:{title:'松旺官网'}
  },
  {
    path: '/phoneNumber',
    name: 'phoneNumber',
    component: () => import(/* webpackChunkName: "about" */ '../views/phoneNumber.vue'),
    meta:{title:'手机号分析'}
  },
  {
    path: '/phoneNumberList',
    name: 'phoneNumberList',
    component: () => import(/* webpackChunkName: "about" */ '../views/phoneNumberList.vue'),
    meta:{title:'手机号分析记录'}
  },
  {
    path: '/phoneNumberResult',
    name: 'phoneNumberResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/phoneNumberResult.vue'),
    meta:{title:'手机号分析结果'}
  },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }

]

// const createRouter = () => new Router({
//   // mode: 'history', // require service support
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes
// })

// const router = createRouter()

// // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
