import {
  Button,
  Select,
  Option,
  Radio,
  RadioGroup,
  RadioButton,
  Row,
  Col,
  Dialog,
  Message,
  Form,
  FormItem,
  Input,
  Card,
  DatePicker,
  TimePicker,
  Divider,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Progress,
  Collapse,
  CollapseItem,
  Pagination
} from 'element-ui'

const element = {
  install: function (Vue) {
    Vue.use(Button)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(RadioButton)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Dialog)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Input)
    Vue.use(Card)
    Vue.use(DatePicker)
    Vue.use(TimePicker)
    Vue.use(Divider)
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Progress);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(Pagination);
    Vue.prototype.$message = Message
  }
}

export default element
