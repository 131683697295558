import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from './element/index'
import 'element-ui/lib/theme-chalk/index.css'
import '@/icons' // icon
import '@/permission.js'
import './assets/iconfont/iconfont.css'
import './style/common.css'
import { Button , Picker,Cascader, DatetimePicker,Form ,Field,Area, RadioGroup, Radio, Calendar,AddressList,Uploader,Grid, GridItem,
        List,Cell,Popup , Tab, Tabs, Progress,ImagePreview,Icon,Tabbar, TabbarItem,NoticeBar,Swipe, SwipeItem,Card} from 'vant';
Vue.use(Cascader);
Vue.use(Button);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Calendar);
Vue.use(AddressList);
Vue.use(List);
Vue.use(Cell);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Progress);
Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Card);
Vue.use(Uploader);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ImagePreview);
//  // 引入 ECharts 主模块
// let echarts = require('echarts/lib/echarts')
// // 引入折线图/柱状图等组件
// require('echarts/lib/chart/line')
// require('echarts/lib/chart/bar')
// require('echarts/lib/chart/radar')
// // 引入提示框和title组件，图例
// require('echarts/lib/component/tooltip')
// require('echarts/lib/component/title')
// require('echarts/lib/component/legend')
// require('echarts/lib/component/legendScroll')//图例滚动
// //vue全局注入echarts
// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(element)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
